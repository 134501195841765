module.exports = {
  //themed images start
  navbar_expanded_logo_icon: require("./themeImages/default/chkfakeLogoExpanded.png"),
  site_logo_large: require("./themeImages/default/ChkfakeLogoLarge.png"),
  enforcement_site_logo_large: require("./themeImages/default/chkfakeEnforcement.png"),
  site_logo: require("./themeImages/default/chkfake_enforce.svg"),
  notification: require("./dashboard/notification.png"),
  //themed images end
  profile_pic: require("./common/profile.png"),
  logo: require("./common/logo.svg"),
  graph: require("./graph/graph.png"),
  kingfisher: require("./common/kingfisher.png"),
  nav_dashboard: require("./common/navigation/dashboard@2x.png"),
  nav_engagement: require("./common/navigation/engagementView@2x.png"),
  nav_gear: require("./common/navigation/gear@2x.png"),
  nav_var_view: require("./common/navigation/verificationView@2x.png"),

  back_arrow: require("./incidentReporting/back-arrow.png"),
  accept_alert_icon: require("./incidentReporting/alerticon.svg"),
  share_icon: require("./incidentReporting/share.png"),
  export_icon: require("./incidentReporting/export.png"),
  export_black: require("./incidentReporting/export_black.png"),
  close_white: require("./incidentReporting/close_white.png"),
  forward_arrow: require("./incidentReporting/forward-arrow.png"),
  lead_popup: require("./incidentReporting/leadpop.svg"),
  navigation_icon: require("./incidentReporting/navigation.png"),
  thumbs_down_in_circle: require("./incidentReporting/thumbsDownInCircle.png"),
  thumbs_up_in_circle: require("./incidentReporting/thumbsUpInCircle.png"),
  interm: require("./incidentReporting/interm.png"),
  noImageShared: require("./incidentReporting/noImageShared.png"),
  rejeted_red: require("./incidentReporting/rejected_red.png"),
  reject_red: require("./incidentReporting/reject_red.png"),

  launch: require("./common/launch.png"),
  more: require("./common/more.png"),
  close_icon: require("./common/close_icon.png"),
  arrow_forward: require("./common/arrow_forward.png"),
  prevPageArrow: require("./common/prevPageArrow.png"),
  pagePlaceholder: require("./common/pagePlaceholder.png"),
  antiCounterfeitPlaceholder: require("./common/anti_counterfeit.png"),
  incidentViewPlaceholder: require("./common/Incident_View.png"),
  unsupportedBrowser: require("./common/unsupported_browser.jpg"),
  red_tick: require("./common/red_tick.png"),
  green_tick: require("./common/green_tick.png"),
  arrow_dropdown_white: require("./dashboard/arrowdown_white.png"),
  arrow_dropdown_yellow: require("./dashboard/yellow_arrowdown.png"),
  dashboard: {
    lock_icon: require("./dashboard/widget_lock.png"),
    header_icon: require("./dashboard/header/Group.png"),
    summary: {
      noVerification: require("./dashboard/summary/noVerification.png"),
      percentageCounterfeits: require("./dashboard/summary/percentageCounterfeits.png"),
      numberOfReportedIncidents: require("./dashboard/summary/numberOfReportedIncidents.png"),
      upArrowGreen: require("./dashboard/summary/upArrowGreen.png"),
      upArrowRed: require("./dashboard/summary/upArrowRed.png"),
      downArrowGreen: require("./dashboard/summary/downArrowGreen.png"),
      downArrowRed: require("./dashboard/summary/downArrowRed.png"),
      //themed images start
      numberOfActiveUsers: require("./dashboard/summary/numberOfActiveUsers.png"),
      noOfOutlet: require("./dashboard/summary/no_of_outlet.svg"),
      samplesCollected: require("./dashboard/summary/samples_collected.svg"),
      outletSellingCounterfeits: require("./dashboard/summary/outlet_selling_counterfeits.svg")
      //themed images end
    },
    filters: {
      //themed images start
      brand_filter: require("./dashboard/filters/filterbrand.png"),
      region_filter: require("./dashboard/filters/filtergeo.png"),
      user_filter: require("./dashboard/filters/filteruser.png"),
      date_filter: require("./dashboard/filters/filtercalender.png"),
      project_filter: require("./dashboard/filters/projectfilter.svg"),
      agency_filter: require("./dashboard/filters/agencyfilter.svg")
      //themed images end
    },
    grid: {
      right_menu: require("./dashboard/grid/right_menu.png"),
      sort_up: require("./dashboard/grid/sort_up.png"),
      sort_down: require("./dashboard/grid/sort_down.png")
    }
  },
  mondelez: {
    appstore_icon: require("./mondelez/appstore_icon.png"),
    chkfake_logo: require("./mondelez/chkfake_logo.png"),
    googleplay_icon: require("./mondelez/googleplay_icon.png"),
    mondelez_logo: require("./mondelez/mondelez_logo.png"),
    mondelez_video_image: require("./mondelez/mondelez_video_image.png")
  },
  notification_in_active: require("./common/menu/notificationInactive.png"),
  profile_in_active: require("./common/menu/profileInactive.png"),
  alert_icon: require("./common/alerts/Alert.png"),
  clear_icon: require("./common/alerts/Clear.png"),
  counterfeit_Alert_icon: require("./common/alerts/Counterfeit_Alert.png"),
  code_scan_icon: require("./common/alerts/code_scan_icon.png"),
  clear_small: require("./common/alerts/clearSmall.png"),

  leadAnalytic: {
    summary: {
      lead_closed: require("./leadAnalytic/closed.png"),
      lead_inprogress: require("./leadAnalytic/inprogresslead.png"),
      lead_open: require("./leadAnalytic/openlead.png"),
      lead_rejected: require("./leadAnalytic/rejectedlead.png"),
      incident_lead: require("./leadAnalytic/totalincidentlead.png"),
      upArrowGreen: require("./dashboard/summary/upArrowGreen.png"),
      upArrowRed: require("./dashboard/summary/upArrowRed.png"),
      downArrowGreen: require("./dashboard/summary/downArrowGreen.png"),
      downArrowRed: require("./dashboard/summary/downArrowRed.png")
    }
  },
  launch_black: require("./lead/black_launch.png"),
  black_forward_arrow: require("./lead/black_forward_arrow.png"),
  lead_navigation: require("./lead/navigation.png"),
  email_black: require("./lead/email_black.png"),
  mobile_black: require("./lead/mobile_black.png"),
  person_black: require("./lead/person_black.png"),
  store_black: require("./lead/store_black.png"),
  location_black: require("./lead/location_black.png"),
  agnecytype: require("./lead/agnecytype_black.png"),
  filter: require("./lead/filter.png"),
  go_back_arrow: require("./lead/back_arrow.png"),
  filter_close: require("./lead/filterclose.png"),
  search_black: require("./lead/search_black.png"),
  header_name_done: require("./lead/header_name_done.png"),
  header_name_cancel: require("./lead/header_name_cancel.png"),
  launch_back_blue: require("./lead/launch_back_blue.svg"),
  hover_cancel: require("./lead/hover_cancel.svg"),
  hover_tick: require("./lead/hover_tick.png"),

  raidAnalytic: {
    summary: {
      raids: require("./raidAnalytics/raids.png"),
      seized_value: require("./raidAnalytics/seized_value.png"),
      seized: require("./raidAnalytics/seized.png"),
      unique_sku: require("./raidAnalytics/unique_sku.png")
    }
  },

  raid_site1: require("./raid/raid_site1.svg"),
  raid_site2: require("./raid/raid_site2.svg"),
  it1: require("./raid/it1.svg"),
  it2: require("./raid/it2.svg"),
  sg1: require("./raid/sg1.svg"),
  sg2: require("./raid/sg2.svg"),
  sg3: require("./raid/sg3.svg"),
  ic_check_bsec: require("./raid/ic_check_bsec.svg"),
  ic_radio_inactive_white: require("./raid/ic_radio_inactive_white.svg"),
  ic_remove_red: require("./raid/ic_remove_red.svg"),
  ic_arrowright_black: require("./raid/ic_arrowright_black.svg"),
  ic_arrowup_black: require("./raid/ic_arrowup_black.svg"),

  new_map_navigation: require("./common/navigation.png"),
  download_black: require("./raid/download_black.png"),
  preview_black: require("./raid/preview_black.png"),
  uploaded: require("./raid/uploaded.png"),
  project_filter_search: require("./project/search_black.png"),
  project_authentication_black: require("./project/authentication_black.png"),
  project_location_black: require("./project/location_black.png"),
  project_product_black: require("./project/product_black.png"),
  project_suspect_black: require("./project/suspect_black.png"),
  project_time_black: require("./project/time_black.png"),
  sent_to_green: require("./project/sent_to_green.png"),
  awaiting: require("./project/awaiting.svg"),
  sample_auth_black: require("./project/sample_auth_black.png"),
  ic_arrowup_square_bsec: require("./common/ic_arrowup_square_bsec.png"),
  ic_arrowdown_square_black: require("./common/ic_arrowdown_square_black.svg"),
  ic_red_green_marker: require("./common/ic_red_green_marker.png"),
  ic_arrowleft_white: require("./common/ic_arrowleft_white.png"),
  ic_fullscreen_exit: require("./common/ic_fullscreen_exit.png"),
  ic_fullscreen: require("./common/ic_fullscreem.png"),
  markerGreen: require("./common/ic_storemarker_green.png"),
  markerRed: require("./common/ic_storemarker_red.png"),
  ic_clustor: require("./common/ic_clustor.png"),
  india_flag: require("./common/india_flag.svg"),
  file_upload: require("./common/file_upload.svg"),
  repeat_offender_active: require("./common/repeat_offender_active.svg"),
  repeat_offender: require("./common/repeat_offender.svg"),
  ic_failed_image: require("./common/ic_failed_image.svg"),
  ic_inprogress_image: require("./common/ic_inprogress_image.svg"),
  ic_uploaded_image: require("./common/ic_uploaded_image.svg"),
  down_arrow: require("./common/down-arrow.png"),
  activity: {
    flag_black: require("./activity/flag_black.png"),
    flag_green: require("./activity/flag_green.png"),
    ic_group: require("./activity/ic_group.svg"),
    ic_location: require("./activity/ic_location.svg"),
    ic_mall: require("./activity/ic_mall.svg"),
    ic_project_bsec: require("./activity/ic_project_bsec.svg"),
    ic_sample_purchase: require("./activity/ic_sample_purchase.svg"),
    ic_siren: require("./activity/ic_siren.svg"),
    ic_suspect: require("./activity/ic_suspect.svg"),
    ic_product: require("./activity/ic_product.png"),
    ic_launch: require("./activity/ic_launch.png"),
    authentication_black: require("./activity/authentication_black.png"),
    suspect_black: require("./activity/suspect_black.png"),
    time_black: require("./activity/time_black.png"),
    ic_navigation: require("./activity/ic_navigation.svg"),
    ic_download_black: require("./activity/ic_download_black.svg"),
    ic_business_entity: require("./activity/ic_business_entity.svg"),
    ic_business_entity_name: require("./activity/ic_business_entity_name.svg"),
    ic_designation: require("./activity/ic_designation.svg"),
    ic_name: require("./activity/ic_name.svg"),
    ic_phone_no: require("./activity/ic_phone_no.svg"),
    ic_seller: require("./activity/ic_seller.svg"),
    ic_typeofurl: require("./activity/ic_typeofurl.svg"),
    ic_webaddress: require("./activity/ic_webaddress.svg"),
    ic_email: require("./activity/ic_email.svg"),
    ic_screenshot: require("./activity/ic_screenshot.svg")
  },
  signup: {
    angldownicon: require("./signup/angldownicon.png"),
    blockquteft: require("./signup/blockquteft.png"),
    blockqutright: require("./signup/blockqutright.png"),
    bottom_leftbgimg: require("./signup/bottom_leftbgimg.svg"),
    brand_logo: require("./signup/brand_logo.png"),
    createfreerightimg: require("./signup/createfreerightimg.svg"),
    email_check: require("./signup/email_check.svg"),
    how_many_pe_rightimg: require("./signup/how_many_pe_rightimg.svg"),
    inviterightimg: require("./signup/inviterightimg.svg"),
    lefterrow: require("./signup/lefterrow.svg"),
    logo: require("./signup/logo.svg"),
    needhelpimg: require("./signup/needhelpimg.svg"),
    new_pass: require("./signup/new_pass.png"),
    productproct: require("./signup/productproct.svg"),
    righterrow: require("./signup/righterrow.svg"),
    successicon: require("./signup/successicon.svg"),
    top_rightbgimg: require("./signup/top_rightbgimg.svg"),
    industry_rightsideimg: require("./signup/industry_rightsideimg.svg"),
    yesicon: require("./signup/yesicon.svg"),
    ic_alert: require("./signup/ic_alert.svg"),
    check: require("./signup/check.png"),
    ic_check_inactive_grey: require("./signup/ic_check_inactive_grey.svg"),
    lock: require("./signup/lock.png"),
    playbutton: require("./signup/play-button.png"),
    close: require("./signup/close.png"),
    green_tick: require("./signup/green_tick.png"),
    baseline_camera: require("./signup/baseline-camera.svg"),
    img_placeholder: require("./signup/img_placeholder.svg"),
    client: {
      one_mg: require("./signup/client/1mg.svg"),
      asianpaints: require("./signup/client/asianpaints.svg"),
      diageo: require("./signup/client/diageo.svg"),
      fmc: require("./signup/client/fmc.svg"),
      hp: require("./signup/client/hp.svg"),
      lerc: require("./signup/client/lerc.svg"),
      mondelez: require("./signup/client/mondelez.svg")
    }
  },
  prev_page: require("./common/previous_page.svg"),
  next_page: require("./common/next_page.svg"),
  up_triangle: require("./common/up-triangle.svg"),
  down_triangle: require("./common/down-triangle.svg")
};
